/* * {
  font-family: 'Lato', sans-serif !important;
} */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Lato",
    "Merriweather" sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#animated-btn {
  display: block;
  background: transparent;
  background-image: linear-gradient(
    to right,
    transparent,
    transparent 50%,
    #6d36f1 50%,
    #3c1698
  );
  background-position: 0;
  background-size: 200% 100%;
  background-position: left bottom;
  transition: background-position 0.3s ease-out;
}

#animated-btn:hover {
  background-position: right bottom;
}

.swiper {
  width: 100%;
  height: 100%;
  padding-top: 12px !important;
  padding-bottom: 30px !important;
}

#swiper {
  width: 100%;
  height: 100%;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.swiper-slide {
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-button-prev,
.swiper-button-next {
  color: #3c1698 !important;
}

a {
  color: var(--mui-palette-primary);
  text-decoration: none;
}

::-webkit-scrollbar {
  width: 9px;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #374354;
  border-radius: 10px;
}

#scroll-down-arrow {
  animation: down 1.5s infinite;
  -webkit-animation: down 1.5s infinite;
}

@keyframes down {
  0% {
    transform: translate(0);
  }
  20% {
    transform: translateY(5px);
  }
  40% {
    transform: translate(0);
  }
}

@-webkit-keyframes down {
  0% {
    transform: translate(0);
  }
  20% {
    transform: translateY(5px);
  }
  40% {
    transform: translate(0);
  }
}
